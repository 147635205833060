import React, { FC, PropsWithChildren, useEffect } from "react";
import { ThemeProvider } from "next-themes";
import type { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ApolloProvider } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import "keen-slider/keen-slider.min.css";
import { GTM_ID, apolloClient, gtmEvent } from "@ukfd/checkout-utils";
import "@assets/chrome-bug.css";
import "@assets/main.css";

type AppProps<P = any> = {
  pageProps: P;
} & Omit<NextAppProps<P>, "pageProps">;

if (!Bugsnag._client) {
  Bugsnag.start({
    releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE || "local",
    enabledReleaseStages: ["production", "staging", "development"],
    apiKey: "0e37e565839410d706c8ea56918e2368",
    plugins: [new BugsnagPluginReact()],
    metadata: {
      source: "Checkout",
    },
  });
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const Noop: FC<PropsWithChildren<unknown>> = ({ children }) => <>{children}</>;

export default function CheckoutApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop;

  const router = useRouter();

  useEffect(() => {
    document.body.classList?.remove("loading");
  }, []);

  useEffect(() => {
    if (GTM_ID) {
      gtmEvent("pageView", pageProps?.dataLayerProps);
    }
  }, [pageProps?.dataLayerProps]);

  return (
    <ErrorBoundary>
      <ThemeProvider
        defaultTheme={router.query.customerType === "trade" ? "trade" : "retail"}
        enableSystem={false}
        themes={["trade", "retail"]}
      >
        <ApolloProvider client={apolloClient}>
          <Head>
            <meta content="initial-scale=1.0, width=device-width" name="viewport" />
            <title>UK Flooring Direct | Checkout</title>
            <link href="https://fonts.googleapis.com" rel="preconnect" />
          </Head>
          <Layout pageProps={pageProps}>
            <Component {...pageProps} />
          </Layout>
        </ApolloProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
