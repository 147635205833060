import { DeepPartial } from "ts-essentials";
import { safelyRound } from "@ukfd/shared-utils";
import { GTM_ENVIRONMENT } from "../constants";
import { gtmGetPageUrl } from "../gtm/gtmGetPageUrl";

const gtmCurrency = "GBP";

export const gtmFormatDataLayerProps = (props: DataLayerProps): GtmDataLayer => {
  return {
    user: props.user ? gtmFormatUser(props.user) : undefined,
    page: {
      url: gtmGetPageUrl(),
      environment: GTM_ENVIRONMENT,
      category: props?.page?.category,
    },
  };
};

export const gtmFormatCheckoutDataLayerProps = (props: DataLayerProps): GtmDataLayer => {
  return {
    ...gtmFormatDataLayerProps(props),
    ecommerce: props.ecommerce ? gtmFormatEcommerceCheckout(props.ecommerce) : undefined,
  };
};

export const gtmFormatCheckoutValidationErrorProps = (
  props: DataLayerValidationErrorProps
): DataLayerValidationError => {
  return {
    errors: props.errors,
  };
};

export const gtmFormatOrderConfirmationDataLayerProps = (
  props: DataLayerProps
): GtmDataLayerOrderConfirmation => {
  return {
    ...gtmFormatDataLayerProps(props),
    ecommerce: props.ecommerce ? gtmFormatEcommerceOrderConfirmation(props.ecommerce) : undefined,
  };
};

export const gtmFormatEcommerceCheckout = (
  props: DataLayerEcommerceProps
): GtmCheckoutEcommerce => {
  return {
    currencyCode: gtmCurrency,
    checkout: {
      actionField: props?.actionField && gtmFormatActionField(props.actionField),
      products: props?.products?.map((product) => gtmFormatProduct(product)),
    },
  };
};

export const gtmFormatEcommerceOrderConfirmation = (
  props: DataLayerEcommerceProps
): GtmOrderConfirmationEcommerce => {
  return {
    currencyCode: gtmCurrency,
    purchase: {
      actionField: props?.actionField && gtmFormatActionField(props.actionField),
      products: props?.products?.map((product) => gtmFormatProduct(product)),
    },
  };
};

export const gtmFormatUser = (props: DataLayerCustomerProps): GtmUser => {
  const { firstName, lastName } = props || {};
  return {
    name: firstName && lastName ? `${firstName} ${lastName}` : undefined,
    email: props?.email,
    logged_in: props?.loggedIn,
    username: props?.email,
    user_id: props?.customerId,
    language: "en",
    returning: props?.loggedIn,
    customer_type: props?.customerType,
    trade_customer_type: undefined,
  };
};

export const gtmFormatActionField = (props: DataLayerActionFieldProps): GtmActionField => {
  const { subtotal, subtotalExVat } = props?.price || {};
  const { deliveryAddress, billingAddress, promo } = props || {};

  const optionalAddresses = () => {
    if (!deliveryAddress || !billingAddress) {
      return;
    }
    if (Array.isArray(deliveryAddress) && Array.isArray(billingAddress))
      return {
        delivery: deliveryAddress[0],
        billing: billingAddress[0],
      };
    else {
      return {
        delivery: deliveryAddress,
        billing: billingAddress,
      };
    }
  };

  return {
    id: props?.id,
    order_id: props?.orderId,
    currency: gtmCurrency,
    subtotal: subtotal && safelyRound(subtotal),
    tax: subtotal && subtotalExVat ? safelyRound(subtotal - subtotalExVat) : undefined,
    subtotal_include_tax: false,
    shipping: props?.shippingCost,
    shipping_method: props?.deliveryMethod,
    coupon: props?.promoCodes,
    revenue: props?.revenue && safelyRound(props.revenue),
    order_discount: promo && safelyRound(promo.discounttotal),
    payment_method: props?.paymentMethod,
    ...optionalAddresses(),
  };
};

const getParentSku = (product: Product) => {
  if (product?.sku) {
    const splitSku = product.sku.split("-");
    splitSku.pop();
    return splitSku.join("-");
  }

  return null;
};

const getChildSku = (product: Product): GtmString => {
  const { details } = product || {};
  return details?.productType === "roll" && details?.productClass === "product"
    ? product?.sku
    : undefined;
};

const getParentProductSku = (product: Product): GtmString => {
  const { details } = product || {};
  return details?.productType === "roll" && details?.productClass === "product"
    ? getParentSku(product)
    : product?.sku;
};

const getMetersSquared = (product: Product): GtmNumber => {
  const { details } = product || {};
  return details?.productType === "pack" && details?.productClass !== "sample"
    ? details?.m2TotalCoverage
    : undefined;
};

const getProductType = (product: Product): string =>
  product?.details?.productClass === "sample" ? "sample" : "purchase";

const getProductPrice = (product: Product): number | undefined =>
  product?.details?.productClass !== "sample"
    ? product?.price?.subtotal && safelyRound(product.price.subtotal)
    : undefined;

const getProductQuantity = (product: Product): GtmNumber => {
  const { details } = product || {};
  if (details?.productType === "pack" && details?.productClass !== "sample") {
    return details?.packs;
  } else if (details?.productType === "roll" && details?.productClass !== "sample") {
    return product.quantity;
  }

  return null;
};

export const gtmFormatProduct = (product: Product): GtmProduct => {
  const { details, price } = product || {};
  return {
    product_id: details?.internalid,
    parent_product_sku: getParentProductSku(product),
    child_product_sku: getChildSku(product),
    url: details?.url,
    name: details?.pageTitle,
    product_type: getProductType(product),
    category: details?.classTitle,
    brand: details?.brand,
    stock: undefined,
    pack_size: details?.m2PerPack,
    price: price?.m2Price && safelyRound(price.m2Price),
    unit_sale_price: price?.m2Price && safelyRound(price.m2Price),
    currency: gtmCurrency,
    earliest_delivery_date: undefined,
    no_of_reviews: details?.prCount,
    reviews_score: details?.prRating,
    colour: details?.colour,
    thickness: details?.thickness,
    style: details?.style,
    installation: details?.installationTypes,
    meters_squared: getMetersSquared(product),
    em: details?.expectedMargin,
    er: details?.expectedRevenue,
    quantity: getProductQuantity(product),
    total: getProductPrice(product),
    total_discount: undefined,
  };
};

export const gtmFormatProductUpdate = (
  product: Product
): DeepPartial<GtmProduct & { id: GtmString }> => {
  const { details } = product || {};
  return {
    name: details?.pageTitle,
    id: getParentProductSku(product),
    price: getProductPrice(product),
    quantity: getProductQuantity(product),
    product_type: getProductType(product),
    brand: details?.brand,
    category: details?.classTitle,
    colour: details?.colour,
    child_product_sku: getChildSku(product),
    meters_squared: getMetersSquared(product),
  };
};
