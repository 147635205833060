import Bugsnag from "@bugsnag/js";
var Logger = {
    log: function log(message) {
        var metadata = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        console.log(message, metadata);
    },
    error: function error(message) {
        var metadata = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        Bugsnag.notify(message, function(event) {
            event.addMetadata(metadata);
        });
        console.error(message, metadata);
    },
    info: function info(message) {
        var metadata = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        console.info(message, metadata);
    },
    warn: function warn(message) {
        var metadata = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        console.warn(message, metadata);
    }
};
export default Logger;
