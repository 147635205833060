import Cookies from "js-cookie";
export var deleteCookie = function(param) {
    var name = param.name;
    var hostname = window.location.hostname;
    var isProduction = hostname.includes("trade.ukflooringdirect.co.uk");
    Cookies.remove(name, {
        domain: isProduction ? "ukflooringdirect.co.uk" : ""
    });
};
export var setCookie = function(param) {
    var name = param.name, value = param.value, _daysUntilExpiry = param.daysUntilExpiry, daysUntilExpiry = _daysUntilExpiry === void 0 ? 7 : _daysUntilExpiry;
    var hostname = window.location.hostname;
    var isProduction = hostname.includes("trade.ukflooringdirect.co.uk");
    Cookies.set(name, value, {
        domain: isProduction ? "ukflooringdirect.co.uk" : "",
        expires: daysUntilExpiry
    });
};
