export const PAYMENT_TOKEN_URL = process.env.NEXT_PUBLIC_PAYMENT_TOKEN_URL;
export const CHECK_3DS_ENROLLMENT = process.env.NEXT_PUBLIC_PAYMENT_CHECK_3DS_ENROLLMENT;
export const PAYMENT_SETUP = process.env.NEXT_PUBLIC_PAYMENT_SETUP;
export const CHECKOUT_URL = process.env.NEXT_PUBLIC_CHECKOUT_URL;
export const LOQATE_BASE_URL = process.env.NEXT_PUBLIC_LOQATE_BASE_URL;
export const LOQATE_API_KEY = process.env.NEXT_PUBLIC_LOQATE_API_KEY;
export const DSO_URL = process.env.NEXT_PUBLIC_DSO_URL;
export const BASKET_URL = process.env.NEXT_PUBLIC_BASKET_URL;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const NODE_ENV = process.env.NODE_ENV;
export const GTM_ENVIRONMENT = process.env.NEXT_PUBLIC_GTM_ENVIRONMENT || "Development";
export const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT;
export const PAYPAL_CLIENT_ID = process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID;
export const PAYPAL_CAPTURE_URL = process.env.NEXT_PUBLIC_PAYPAL_CAPTURE_URL;
export const PAYPAL_VOID_URL = process.env.NEXT_PUBLIC_PAYPAL_VOID_URL;
export const CARDINAL_URL = process.env.NEXT_PUBLIC_CARDINAL_URL;
export const CARDINAL_COLLECT_URL = process.env.NEXT_PUBLIC_CARDINAL_COLLECT_URL;
export const EXPONEA_TOKEN = process.env.NEXT_PUBLIC_EXPONEA_TOKEN;
export const HOME_URL = process.env.NEXT_PUBLIC_HOME_URL;
export const FEATURE_RELATED_PRODUCTS = process.env.NEXT_PUBLIC_FEATURE_RELATED_PRODUCTS;
export const HOTJAR_ENABLED = process.env.NEXT_PUBLIC_HOTJAR_ENABLED;

export const SESSION_STORAGE_ORDER_KEY = "ukfd-order";

export const UKFD_COOKIES = {
  PAYLOAD: "ukfd-payload",
  DSO: "ukfd-dso",
  PROMO_CODES: "ukfd-promo-codes",
  PAYMENT_ATTEMPTED: "ukfd-payment-attempted",
};

export const UKFD_PAYMENT_COOKIES = [UKFD_COOKIES.PAYLOAD, UKFD_COOKIES.DSO];
