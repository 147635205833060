import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import Logger from "./logger";
var logGraphQLErrors = function(error, data, metadata) {
    var errors = [];
    if (error) {
        errors.push(error);
    }
    if (data === null || data === void 0 ? void 0 : data.errors) {
        data.errors.forEach(function(err) {
            if (err.originalError) {
                errors.push(err.originalError);
            } else if (err.message) {
                errors.push(new Error(err.message));
            }
        });
    }
    if (errors.length) {
        errors.forEach(function(err) {
            return Logger.error(err, _object_spread({
                errorType: "GraphQL"
            }, metadata && _object_spread({}, metadata)));
        });
        return errors;
    }
    return null;
};
export default logGraphQLErrors;
