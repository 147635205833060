import Cookies from "js-cookie";
var getCheckoutRequiredData = function() {
    var getDataFromCookieOrURL = function(cookieName) {
        var name = cookieNameToQueryParamMap[cookieName];
        var params = new URLSearchParams(window.location.search);
        var value = Cookies.get(cookieName) || params.get(name) || "";
        return {
            name: name,
            value: value
        };
    };
    var cookieNameToQueryParamMap = {
        basketId: "id",
        vatIncluded: "isVatOn",
        ukfdCustomerType: "customerType",
        ukfdUserID: "customerId",
        custbody_google_analytics_client_id: "custbody_google_analytics_client_id",
        custbody_captured_data_webstore: "custbody_captured_data_webstore"
    };
    var data = {};
    Object.keys(cookieNameToQueryParamMap).forEach(function(key) {
        var ref = getDataFromCookieOrURL(key), name = ref.name, value = ref.value;
        data[name] = value;
    });
    return data;
};
export default getCheckoutRequiredData;
