var scrollIntoView = function(elementId) {
    if (!elementId) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        return;
    }
    var yOffset = -70;
    var element = document.getElementById(elementId);
    if (!element) {
        return;
    }
    var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({
        top: y,
        behavior: "smooth"
    });
};
export default scrollIntoView;
