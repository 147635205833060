var withRetry = function(predicate) {
    var count = 0;
    return new Promise(function(resolve) {
        var result = predicate();
        if (!result) {
            var interval = setInterval(function() {
                result = predicate();
                if (result) {
                    clearInterval(interval);
                    resolve(result);
                } else if (count === 6) {
                    clearInterval(interval);
                    resolve("");
                }
                count++;
            }, 500);
        } else {
            resolve(result);
        }
    });
};
export default withRetry;
