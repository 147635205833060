import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_without_properties from "@swc/helpers/src/_object_without_properties.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import Logger from "./logger";
import urlBuilder from "./urlBuilder";
function ukfdFetch(input) {
    return _ukfdFetch.apply(this, arguments);
}
function _ukfdFetch() {
    _ukfdFetch = _async_to_generator(function(input) {
        var init, params, rest, url, res;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    init = _arguments.length > 1 && _arguments[1] !== void 0 ? _arguments[1] : {};
                    params = init.params, rest = _object_without_properties(init, [
                        "params"
                    ]);
                    url = urlBuilder(input, params);
                    return [
                        4,
                        fetch(url, rest)
                    ];
                case 1:
                    res = _state.sent();
                    if (!res.ok) {
                        Logger.error(new Error("Failed to fetch: ".concat(res.status, " - ").concat(res.statusText)));
                        throw new Error("Failed to fetch: ".concat(res.status, " - ").concat(res.statusText));
                    }
                    return [
                        2,
                        res.json()
                    ];
            }
        });
    });
    return _ukfdFetch.apply(this, arguments);
}
export default ukfdFetch;
