import {
  gtmFormatCheckoutDataLayerProps,
  gtmFormatCheckoutValidationErrorProps,
  gtmFormatDataLayerProps,
  gtmFormatOrderConfirmationDataLayerProps,
} from "./gtmFormatters";

export const gtmEvent = (event: string, eventData: any): void => {
  if (!event || !eventData) {
    return;
  }
  switch (eventData.page.category) {
    case "checkout":
      return window.dataLayer?.push({
        event,
        ...gtmFormatCheckoutDataLayerProps(eventData),
      });
    case "confirmation":
      return window.dataLayer?.push({
        event,
        ...gtmFormatOrderConfirmationDataLayerProps(eventData),
      });
    case "checkout-validation":
      eventData.page.category = "checkout";
      return window.dataLayer?.push({
        event,
        ...gtmFormatCheckoutValidationErrorProps(eventData),
      });

    default:
      return window.dataLayer?.push({
        event,
        ...gtmFormatDataLayerProps(eventData),
      });
  }
};
