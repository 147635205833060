import isEmpty from "lodash/isEmpty";
import Logger from "./logger";
var urlBuilder = function(baseUrl) {
    var params = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (!baseUrl) {
        Logger.error(new Error("baseUrl is a required parameter!"));
        return "";
    }
    if (isEmpty(params)) {
        return baseUrl;
    }
    var url = new URL(baseUrl);
    return "".concat(url, "?").concat(Object.keys(params).map(function(key) {
        return "".concat(key, "=").concat(params[key]);
    }).join("&"));
};
export default urlBuilder;
