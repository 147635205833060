import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import crossFetch from "cross-fetch";
import { Logger } from "@ukfd/shared-utils";
import { GRAPHQL_ENDPOINT } from "./constants";

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
  fetch: process.env.NODE_ENV === "test" ? crossFetch : fetch,
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, message, locations }) => {
      return Logger.error(
        new Error(
          `[GraphQL error]: Response: ${JSON.stringify(
            response
          )} Message: ${message}, Location: ${JSON.stringify(locations)} on ${
            operation.operationName
          }, ErrorCode: ${extensions.code}`
        )
      );
    });
  }

  if (networkError) {
    Logger.error(new Error(`[Network error]: ${networkError}`));
  }
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

const apolloClient = new ApolloClient({
  link: from([errorLink, retryLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default apolloClient;
